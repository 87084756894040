:root {
	--color-soar-yellow: #eed926
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
tr,
td,
span,
strong,
div,
text,
input {
  font-family: 'Manrope', sans-serif !important;
}

.row {
  --bs-gutter-x: none;
}

body {
  margin: 0;
  padding: 0;
}

.navbar-dark .navbar-nav .nav-link {
    color: #eed926 !important;
}
