.rs-picker-default .rs-picker-toggle.rs-btn{
    display: none;
}

.rs-picker-menu {
    z-index: 9999999 !important;
    background: inherit;
    color: white;
    width: 100%;
    box-shadow: none;
}
.rs-picker-daterange-panel {
    z-index: 9999999;
}
.rs-picker-toolbar-ranges {
    text-align: left;
}

.rs-picker-toggle-wrapper {
    margin-right: 10px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: rgba(0, 0, 0, 0.87);
}

.rs-picker-toggle {
    border-color: transparent;
}

.rs-btn-primary {
    background-color: #eed926;
    color: black;
    border-color: #eed926;
    margin: 1px 5px;
    padding: 5px 25px;
}

.rs-btn-primary:hover,
.rs-btn-primary:focus,
.rs-btn-primary:active,
.rs-btn-primary:disabled {
    background-color: #eed926;
    color: black;
    border-color: #eed926;
}

.rs-picker-toolbar-ranges button {
    display: none;
}

.rs-picker-menu .rs-calendar {
    width: 50%;
}

.rs-picker-daterange-header {
    border-bottom: 1px solid rgba(255,255,255,0.3) !important;
}

.rs-calendar:first-child {
    border-right: 1px solid rgba(255,255,255,0.3) !important;
}

.rs-calendar-month-dropdown,
.rs-picker-toolbar {
    border-top: 1px solid rgba(255,255,255,0.3) !important;;
}

.rs-calendar-month-dropdown-row {
    color: white;
    background-color: rgba(33,37,41,1);
}

.rs-calendar-show-month-dropdown .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
    color: #eed926;
}

.rs-calendar-month-dropdown {
    background-color: transparent;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    color: black;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    color: rgba(0, 0, 0, 0.87);
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: #eed926;
    color: black;
}

.rs-calendar-table-cell:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
}

.rs-calendar-table-cell-content {
    width: 100% !important;
}

.rs-calendar-table-cell-is-today {
    box-sizing: none;
    box-shadow: 1px solid red !important;
}

.rs-calendar-table-cell-content:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #eed926;
    color: rgba(0, 0, 0, 0.87);
}