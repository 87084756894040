
img.choose-file-image-preview {
	width: 100%;
	margin: 0;
	padding: 0;
}

.choose-file {
	padding: 12px;
	background: transparent;
	width: 100%;
    height: 100%;
}

.choose-file p {
	color: white;
	text-align: center;
	font-size: 1.6rem;
	font-weight: bold;
	padding: 0;
	margin: 0;
}

.choose-file p.choose-file-extra-info {
	color: rgba(0, 0, 0, 0.7);
	margin-top: 50px;
	font-size: 0.9rem;
}
.choose-file img {
	width: 45%;
	margin: 0 auto;
	display: block;
	padding: 20px;
	margin-top: 0;
	color: #00A2FF;
}

.choose-file-image-preview-container {
	border: 1px dashed #a3a3a3;
	background: #d3d3d3;
	width: 100%;
	margin-top: 90px;
}

button.btn.btn-primary.btn-choose-image.btn.btn-secondary {
	margin: 0 auto;
	display: block;
	margin-top: 30px;
	margin-bottom: 30px;
	color: #000;
	background-color: #eed926;
	text-transform: uppercase;
}