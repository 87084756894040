.task-single-page h3, 
.task-single-page h5 
{
    text-align: center;
}

.task-single-page {
    border: 1px solid black;
    margin-top: 15px;
    padding: 15px;    
}