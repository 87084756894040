.dotdotdot:after {
    font-weight: 300;
    content: '...';
    display: inline-block;
    width: 20px;
    text-align: left;
    animation: dotdotdot 1.5s linear infinite;
}

@keyframes dotdotdot {
    0%   { content: '...'; }
    25% { content: ''; }
    50% { content: '.'; }
    75% { content: '..'; }
  }