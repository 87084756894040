.leaflet-control-attribution.leaflet-control {
    display: none;
}

.leaflet-control-screenshot {
    border: none !important;
    margin: 0 0 12px 0;
}


a.leaflet-control-screenshot-button {
    width: unset !important;
    height: unset !important;

    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background-color: #eed926;
    color: black;
    text-transform: uppercase;
    border: 1px solid #eed926;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

a.leaflet-control-screenshot-button:hover, a.leaflet-control-screenshot-button:focus, a.leaflet-control-screenshot-button:active {
    background-color: #eeda26;
    color: black;
    border-color: #eed926;
    transition: linear 0.1s;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.7);
}