.task {
    padding: 15px;
    margin: 15px 0px;
    border: 1px solid black;
}

.task .single-feature-map {
    height: 300px;
}

.task .btn {
    margin-right: 10px;
}

h1 {
    text-align: center;
    margin-top: 20px;
}

.sort-tasks-select {
    width: 180px;
    height: 32px;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    font-size: 16px;
    transition: box-shadow 150ms ease;
}

.sort-tasks-label {
    margin-right: 12px;
}

.sort-tasks-container {
    display: block;
    margin: 0 auto;
    text-align: right;
}

i.refresh-tasks {
    cursor: pointer;
    margin-left: 12px;
}

.task-table {
    margin-bottom: 100px;
}

.task-table .expand-cell {
    cursor: pointer;
}

.task-table .reset-expansion-style {
    background-color: #e6ebf1;
}

.download-aoi-buttons {
    text-align: center;
}